import { $qs, $qsa } from '../utils/QuerySelector';



function resetAllTabs(thisTabsContainer) {
    let allTabs = $qsa('.js-tab-control, .js-tab-content', thisTabsContainer);
    
    [...allTabs].forEach(tab => tab.classList.remove('active'));
}

function toggleTabs(clickedTab, thisTabsContainer) {
    let clickedTabIndex = clickedTab.dataset.tab;
    let clickedTabContent = $qs(`.js-tab-content[data-tab="${clickedTabIndex}"]`, thisTabsContainer);
    
    resetAllTabs(thisTabsContainer);
    
    clickedTab.classList.add('active');
    clickedTabContent.classList.add('active');
}

function Tabs() {
    const tabsContainers = $qsa('.js-tabs');

    if (!tabsContainers) return;
    
    tabsContainers && [...tabsContainers].forEach(tabsContainer => {
        const tabControls = $qsa('.js-tab-control', tabsContainer);
        const tabContents = $qsa('.js-tab-content', tabsContainer);

        tabsContainer && [...tabControls].forEach(tabControl => tabControl.addEventListener('click', () => toggleTabs(tabControl, tabsContainer), false));
    });
}

export default Tabs;