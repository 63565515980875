import { $qs, $qsa } from '../utils/QuerySelector';



function gallerySlider() {
    const sliderElements = $qsa('.js-gallery-slider');

    if (!sliderElements) return;

    [...sliderElements].forEach(sliderElement => {
        const sliderParent = sliderElement.closest('section');
        const prevButton = $qs('.js-prev', sliderParent);
        const nextButton = $qs('.js-next', sliderParent);

        const slider = $(sliderElement).slick({
            rows: 0,
            dots: false,
            arrows: true,
            infinite: true,
            centerMode: true,
            variableWidth: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 700
        });

        prevButton.addEventListener('click', () => slider.slick('slickPrev'), false);
        nextButton.addEventListener('click', () => slider.slick('slickNext'), false);
    })
}

function contentSlider() {
    const sliderElements = $qsa('.js-content-slider');

    if (!sliderElements) return;

    [...sliderElements].forEach(sliderElement => {
        const sliderParent = sliderElement.closest('section');
        const prevButton = $qs('.js-prev', sliderParent);
        const nextButton = $qs('.js-next', sliderParent);

        const slider = $(sliderElement).slick({
            rows: 0,
            dots: false,
            arrows: true,
            infinite: true,
            centerMode: true,
            variableWidth: true,
            // autoplay: true,
            autoplaySpeed: 2000,
            adaptiveHeight: false,
            speed: 700
        });

        prevButton.addEventListener('click', () => slider.slick('slickPrev'), false);
        nextButton.addEventListener('click', () => slider.slick('slickNext'), false);

        var prevContentSlide = $('[data-slick-index=-1]');
        prevContentSlide.addClass('prev-content-slide');
        var nextContentSlide = $('[data-slick-index=1]');
        nextContentSlide.addClass('next-content-slide');
        slider.on('beforeChange', function (event, slick, currentSlide) {
            prevContentSlide.removeClass('prev-content-slide');
            nextContentSlide.removeClass('next-content-slide');
            // re-define prev and next slides
            prevContentSlide = $('[data-slick-index='+ (currentSlide - 1) + ']');
            nextContentSlide = $('[data-slick-index='+ (currentSlide + 1) + ']');
            prevContentSlide.addClass('prev-content-slide');
            nextContentSlide.addClass('next-content-slide');
        });
    })
}
function vacancySlider() {

    $('.js-vacancy-slider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        var currentNum = ('0' + i).slice(-2)
        var totalNum = ('0' + slick.slideCount).slice(-2)
        $('.js-slider-pagi').text(currentNum + '/' + totalNum);
    });


    const sliderElements = $qsa('.js-vacancy-slider');

    if (!sliderElements) return;

    [...sliderElements].forEach(sliderElement => {
        const sliderParent = sliderElement.closest('section');
        const prevButton = $qs('.js-prev', sliderParent);
        const nextButton = $qs('.js-next', sliderParent);

        const slider = $(sliderElement).slick({
            dots: false,
            arrows: true,
            // fade: true,
            autoplay: true,
            adaptiveHeight: false,
            centerMode: true,
            autoplaySpeed: 3500,
            slidesToShow: 2,
            speed: 1200,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        prevButton.addEventListener('click', () => slider.slick('slickPrev'), false);
        nextButton.addEventListener('click', () => slider.slick('slickNext'), false);
    })


}
function singleSlider() {
    const sliderElements = $qsa('.js-single-slider');

    if (!sliderElements) return;

    [...sliderElements].forEach(sliderElement => {
        const sliderParent = sliderElement.closest('section');
        const prevButton = $qs('.js-prev', sliderParent);
        const nextButton = $qs('.js-next', sliderParent);

        const slider = $(sliderElement).slick({
            rows: 0,
            dots: false,
            arrows: true,
            infinite: true,
            centerMode: true,
            variableWidth: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 700
        });

        prevButton.addEventListener('click', () => slider.slick('slickPrev'), false);
        nextButton.addEventListener('click', () => slider.slick('slickNext'), false);
    })
}
function Sliders() {
    gallerySlider();
    contentSlider();
    vacancySlider();
    singleSlider();
}

export default Sliders;