// Components
import Tabs from './components/Tabs';
import Sliders from './components/Sliders';
import ScrollAnimations from './components/ScrollAnimations';

// Animations

function init() {
    // Components
    Tabs();
    Sliders();
    ScrollAnimations();
    // Animations

}

init();